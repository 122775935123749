import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import ReportsHistory from 'src/components/reports-history/reports-history';
import SEO from 'src/components/seo';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Reports History" />
      <PrivateRoute>
        <ReportsHistory />
      </PrivateRoute>
    </Layout>
  );
};

export default IndexPage;
