import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import download from 'downloadjs';
import graphql from 'graphql-tag';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { microservicesClient } from 'src/apollo/client';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import Panel from 'src/components/panel/panel';
import Spinner from 'src/components/spinner/spinner';
import { PageMaxWidth } from 'src/components/util-components/util-components';

const LIST_REPORTS = graphql`
  query AllMetadata {
    allReportMetadata(skip: 2, limit: 20) {
      fileName
      status {
        state
        message
      }
      url
    }
  }
`;

const ReportsHistory: React.FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(LIST_REPORTS, { client: microservicesClient });
  const cols = useMemo<DataCols<any>>(() => {
    return [
      { key: 'report', title: 'Report', getValue: (r) => r.fileName },
      { key: 'status', title: 'Status', getValue: (r) => reportStatus(r) },
      { key: 'timestamp', title: 'Date', getValue: (r) => dateHack(r.fileName) },
      { key: 'download', getValue: (r) => <Download {...r} /> },
    ];
  }, [t]);

  return (
    <PageMaxWidth>
      <Panel floatingTitle="Download History">
        {loading && <Spinner />}
        {data && <AdminTable columns={cols} data={data.allReportMetadata} />}
      </Panel>
    </PageMaxWidth>
  );
};

const Download: React.FC<{ fileName: string; url: string }> = ({ fileName, url }) => {
  return (
    <Button
      linkStyle
      onClick={async () => {
        const res = await fetch(`${getEnvConfig().GATEWAY_BASE_URL}${url}`, {
          headers: { authorization: 'API_TEST' },
        });
        download(await res.blob(), fileName, 'text/plain');
      }}
    >
      Download
    </Button>
  );
};

const dateHack = (fileName: string) => {
  const dateString = fileName.split('.');
  dateString.pop();
  const date = new Date(dateString.join('.'));
  return date.toLocaleString('en-US');
};

const reportStatus = (report, t?: TFunction) => {
  switch (report.status.state) {
    case 'COMPLETE':
      return 'Complete';
    case 'PENDING':
      return 'Pending';
    case 'PROCESSING':
      return 'Processing';
    case 'ERROR':
      return 'Error';
  }
};

export default ReportsHistory;
